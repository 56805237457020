import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';
import { memo, useCallback, useState } from 'react';
import { DigizuiteImageLoaderProps, digizuiteLoader } from './loaders/digizuiteLoader';
import { defaultLoader } from './loaders/defaultLoader';
import { StyledImageWrapper, StyledImageWrapperProps } from './styled';
import { LineSpinner } from '~/shared/components';

export type ImageProps = NextImageProps &
    DigizuiteImageLoaderProps & {
        /**
         * Show the LineSpinner while loading
         */
        showSpinner?: boolean;
        /**
         * Delay of the spinner
         */
        spinnerDelay?: number;
        /**
         * Callback for when image is loaded
         */
        onLoadCallback?: () => void;
    } & Omit<StyledImageWrapperProps, 'isLoading'>;

type OnLoadingComplete = {
    naturalWidth: number;
    naturalHeight: number;
};

export const Image = memo(
    ({
        src = '',
        width: initialWidth = 0,
        height: initialHeight = 0,
        layout = 'responsive',
        skeletonShade = 'light',
        hoverStyle = 'none',
        showSpinner = true,
        spinnerDelay = 1,
        quality = 80,
        alt = '',
        onLoadAnimation = 'fade',
        onLoadCallback,
        mimeType,
        aspectType,
        cH,
        cW,
        gravity,
        assetId,
        invert,
        lazyBoundary = '0px',
        focalPoint,
        dimensions,
        ...rest
    }: ImageProps) => {
        const [isLoading, setIsLoading] = useState(true);

        /**
         * @TODO Look into using custom hook to cache src dimensions
         * to prevent additional layout shifts for repeated images
         */
        const setDimensions = layout !== 'fill';
        const [width, setWidth] = useState(setDimensions ? initialWidth : undefined);
        const [height, setHeight] = useState(setDimensions ? initialHeight : undefined);

        const isStatic = !/^https?:\/\//.test(src.toString());
        const isSvg = mimeType === 'image/svg+xml' || src.toString().includes('.svg');

        const loader = isStatic
            ? undefined
            : isSvg
            ? defaultLoader
            : digizuiteLoader({
                  height,
                  mimeType,
                  aspectType,
                  cH,
                  cW,
                  gravity,
                  assetId,
                  focalPoint,
                  dimensions,
              });

        const objectPosition = focalPoint
            ? `${focalPoint[0] * 100}% ${focalPoint[1] * 100}%`
            : undefined;

        const onLoadHandler = useCallback(
            ({ naturalWidth, naturalHeight }: OnLoadingComplete) => {
                const noDimensions = !width || !height;
                setIsLoading(false);
                onLoadCallback?.();

                /**
                 * Setting naturalWidth and naturalHeight on the image element
                 * if non provided.
                 * isLoading ensures only initial render.
                 * Updating dimensions on the image will retrigger onLoadingComplete.
                 */

                if (isLoading && noDimensions && setDimensions) {
                    setWidth(naturalWidth);
                    setHeight(naturalHeight);
                }
            },
            [height, isLoading, onLoadCallback, setDimensions, width]
        );

        return (
            <StyledImageWrapper
                isLoading={isLoading}
                skeletonShade={skeletonShade}
                hoverStyle={hoverStyle}
                onLoadAnimation={rest.priority ? 'none' : onLoadAnimation}
                invert={invert}
            >
                <NextImage
                    loader={loader}
                    src={src}
                    onLoadingComplete={onLoadHandler}
                    width={width}
                    height={height}
                    layout={layout}
                    quality={rest.priority ? 75 : quality}
                    alt={alt}
                    lazyBoundary={lazyBoundary}
                    objectPosition={objectPosition}
                    {...rest}
                />
                {showSpinner && isLoading && <LineSpinner delay={spinnerDelay} mode="inView" />}
            </StyledImageWrapper>
        );
    }
);
